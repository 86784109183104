import { SchemaTypes } from './model';

const SCHEMA_TYPES: Record<string, SchemaTypes> = {
  ORGANIZATION_SCHEMA_TYPE: 'Organization',
  ARTICLE_SCHEMA_TYPE: 'Article',
  WEB_PAGE_SCHEMA_TYPE: 'WebPage',
  BLOG_POSTING_SCHEMA_TYPE: 'BlogPosting',
};

export default SCHEMA_TYPES;

import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import { ISchemaData, schemaService, SchemaTypes } from '../../../utils/schemaService';
import SCHEMA_TYPES from '../../../utils/schemaService/constants';

import { IStructuredPageSchemaProps } from './model';

const StructuredPageSchema: FC<IStructuredPageSchemaProps<ISchemaData>> = ({
  additionalSchema,
  schemaData,
}) => {
  let schemas: SchemaTypes[] = [SCHEMA_TYPES.WEB_PAGE_SCHEMA_TYPE];

  if (additionalSchema === SCHEMA_TYPES.ORGANIZATION_SCHEMA_TYPE) {
    schemas = [additionalSchema];
  } else if (additionalSchema === SCHEMA_TYPES.ARTICLE_SCHEMA_TYPE) {
    const additionalSchemaFormat = SCHEMA_TYPES.BLOG_POSTING_SCHEMA_TYPE;
    additionalSchemaFormat && schemas.push(additionalSchemaFormat);
  } else {
    additionalSchema && schemas.push(additionalSchema);
  }

  const structuredSchemaScripts = schemaService.getSchemas(schemas, schemaData);

  return <Helmet script={structuredSchemaScripts} />;
};

export default StructuredPageSchema;
